import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';


const DecorationWrapper = styled.div<DecorationWrapperProps>`
  background-image: url(${({ backgroundURL }) => backgroundURL});
  background-repeat: repeat;
  background-position-x: ${({ side }) => (side === 'left' ? 'right' : 'left')};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  position: absolute;
  left: ${({ left }) => (left === undefined ? 'unset' : left)};
  top: ${({ top }) => (top === undefined ? 'unset' : top)};
  bottom: ${({ bottom }) => (bottom === undefined ? 'unset' : bottom)};
  right: ${({ right }) => (right === undefined ? 'unset' : right)};
  z-index: 1;
  transform: ${({ transform }) => transform};
`;

interface DecorationsWhiteProps {
  height?: string,
  width?: string,
  left?: string,
  right?: string,
  bottom?: string,
  top?: string,
  transform?: string,
  side: 'left' | 'right',
}

interface DecorationWrapperProps extends DecorationsWhiteProps {
  backgroundURL: string,
}

const DecorationsWhite = ({
  height,
  width,
  left,
  right,
  top,
  bottom,
  side,
  transform,
  ...props
}: DecorationsWhiteProps) => {
  const data = useStaticQuery(graphql`
    {
      svg: file(name: {eq: "decorationCircleWhite"}) {
        publicURL
      }
    }
  `);

  return (
    <DecorationWrapper
      backgroundURL={data.svg.publicURL}
      height={height}
      width={width}
      left={left}
      right={right}
      bottom={bottom}
      top={top}
      side={side}
      transform={transform}
      {...props}
    />
  );
};

export default DecorationsWhite;
