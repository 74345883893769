import React, { useContext } from 'react';
import styled, { ThemeContext } from 'styled-components';
import DecorationsWhite from '../../components/DecorationsWhite';
import useWindowWidth from '../../hooks/useWindowWidth';
import Container from '../../components/Container';
import RodoMd from './RodoMd';


const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.blue};
  min-height: 100vh;
  padding-bottom: 2vh;
`;

const RodoLayout = () => {
  const { screenSizeWidthWithoutPx, colors } = useContext(ThemeContext);
  const windowSize = useWindowWidth();

  return (
    <Wrapper>
      <DecorationsWhite
        height="350px"
        width={(windowSize >= screenSizeWidthWithoutPx.medium) ? '9%' : '0'}
        top="0"
        transform="translateY(250%)"
        right="0"
        side="right"
      />
      <Container
        backgroundColor={colors.blue}
      >
        <RodoMd />
      </Container>
      <DecorationsWhite
        height="510px"
        width={(windowSize >= screenSizeWidthWithoutPx.small) ? '10%' : '0'}
        top="150px"
        left="0"
        side="left"
      />
      <script
        id="CookieDeclaration"
        src="https://consent.cookiebot.com/c6afba89-a77d-4df1-af4c-ad3e661aa8b6/cd.js"
        type="text/javascript"
        async
      />
    </Wrapper>
  );
};

export default RodoLayout;
