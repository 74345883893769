import React from 'react';
import styled from 'styled-components';


/** the calculations are here to prevent div's width from being
 wider than the viewport's width.

 The goal is to have container's width just like bootstrap proposed it:
 For 576px viewport, container that has 540px
 For 768px viewport, container that has 720px
 For 992px viewport, container that has 960px
 For 1200px viewport, container that has 1140px

 So the number we substract is just the difference between breakpoint
 and desired container's width.
 for eq: With extraSmall we have 576px, so 576px - 36px = 540px for container just how we wanted!
 */

const ContainerWrapper = styled.div`
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  position: relative;
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraSmall}) {
    width: calc(${({ theme }) => theme.screenSizeWidth.extraSmall} - 36px);
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.small}) {
    width: calc(${({ theme }) => theme.screenSizeWidth.small} - 48px);
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.medium}) {
    width: calc(${({ theme }) => theme.screenSizeWidth.medium} - 32px);
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.large}) {
    width: calc(${({ theme }) => theme.screenSizeWidth.large} - 60px);
  }
  
  @media(min-width: ${({ theme }) => theme.screenSizeWidth.extraLarge}) {
    width: calc(${({ theme }) => theme.screenSizeWidth.extraLarge} - 180px);
  }
`;

const ContainerWithBackground = styled.div<{ backgroundColor?: string }>`
  width: 100%;
  height: 100%;
  background-color: ${({ backgroundColor, theme }) => (
    backgroundColor === null ? theme.colors.white : backgroundColor)};
`;

interface ContainerProps {
  children: object,
  backgroundColor?: string,
}

const Container = ({ children, backgroundColor }: ContainerProps) => (
  <ContainerWithBackground
    backgroundColor={backgroundColor}
  >
    <ContainerWrapper>
      {children}
    </ContainerWrapper>
  </ContainerWithBackground>
);

export default Container;
