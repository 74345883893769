import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useMdxTranslation } from '../../hooks/useMdxTranslation';


const RodoMd = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^../other/rodo.mdx$/"}}) {
        edges {
          node {
            relativePath
            childMdx {
              body
            }
          }
        }
      }
    }
  `);

  const translationObject = useMdxTranslation(data);

  return (
    <MDXRenderer>
      {translationObject.childMdx.body}
    </MDXRenderer>
  );
};

export default RodoMd;
