import { useContext, useMemo } from 'react';
import LanguageContext from '../contexts/LanguageContext';


export interface TranslationNode {
  relativePath: string,
  childMarkdownRemark: {
    html: string,
  },
  childMdx: {
    body: string,
  },
}

export interface TranslationData {
  allFile: {
    edges: Array<{
      node: TranslationNode,
    }>,
  },
}


export function useMdxTranslation(
  graphqlData: TranslationData
): TranslationNode {
  const languageCode = useContext(LanguageContext);
  const translationObject = useMemo(
    () => graphqlData.allFile.edges.reduce<Record<string, TranslationNode>>(
      (collector, file) => ({
        ...collector,
        [file.node.relativePath.split('/')[0]]: file.node,
      }),
      {}
    ),
    [graphqlData.allFile.edges]
  );

  const currentTranslation = useMemo(
    () => translationObject[languageCode],
    [languageCode, translationObject]
  );

  return currentTranslation;
}
